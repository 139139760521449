body {
  font-family: 'Varela Round', Tahoma, sans-serif;
  font-size: 14px;
}

.text-content{
  width: 960px;
  margin: 0 auto;
}

.header h1, h2, h3 {
}


#root {
  margin-bottom: 0;
}

#plot-svg{
  pointer-events: none;
  z-index: 10;
}

g.axis path{
  stroke-width: 2px;
}
g.tick line{
  stroke-width: 2px;
}
g.tick text{
  font-size: 12px;
}

svg text.label{
  font-size: 14px;
}

svg path.cetal{
  stroke: darkblue;
}

svg path.martello{
  stroke: darkgreen;
}

svg path.nakeddepthfirst{
  stroke: darkgoldenrod;
}

svg path.rubin{
  stroke: darkred;
}

svg path.vacul{
  stroke: black;
}

svg path.vanhorn{
  stroke: saddlebrown;
}

svg path.median{
  stroke-width: 3px;
}

svg path.mean{
  stroke-width: 2px;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #000;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}